import { BaseController } from '../../utilities/base_controller';
import { isHTMLTextAreaElement } from "../../utilities/elements";
import { useEventListener } from "../../mixins/use_event_listener";
import { useIntersection } from "../../mixins/use_intersection";
export class AutosizeController extends BaseController {
    connect() {
        let { teardown } = useIntersection(this, this.el, this.appear);
        this._unobserveIntersection = teardown;
        if (!isHTMLTextAreaElement(this.el)) {
            throw new Error(`Expected controller to be attached to a textarea, but was a '${this.el.tagName}'`);
        }
        requestAnimationFrame(() => {
            this.el.style.resize = "none";
            this.el.style.boxSizing = "border-box";
            this._handler();
            useEventListener(this, window, ['resize'], this._handler);
            useEventListener(this, this.el, ['input', 'change', 'focus'], this._handler, { debounce: 100 });
        });
    }
    appear(_entry) {
        this.autosize(this.el);
        this._unobserveIntersection();
    }
    _handler() {
        this.autosize(this.el);
    }
    autosize(element) {
        let offset = element.offsetHeight - element.clientHeight;
        element.style.height = "auto";
        element.style.height = element.scrollHeight + offset + "px";
    }
}
