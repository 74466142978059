var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseController } from "../../utilities/base_controller";
import { getScrollParent, scrollAbsoluteBottom } from "../../utilities/scroll";
export class ScrollToBottomController extends BaseController {
    get _mode() {
        return this.hasModeValue ? this.modeValue : "document";
    }
    get _scrollTarget() {
        let target;
        if (this._mode == "document") {
            target = window;
        }
        else {
            target = getScrollParent(this.el);
        }
        return target;
    }
    scroll(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            if (this._scrollTarget) {
                yield scrollAbsoluteBottom(this._scrollTarget);
            }
        });
    }
}
ScrollToBottomController.values = {
    mode: String,
};
