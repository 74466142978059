import { signalEventName } from "./events";
import { SignalBaseController } from "./base_controller";
export class SignalActionController extends SignalBaseController {
    _onSignal(payload) {
        let value = payload.value;
        if (!this.hasWhenValue) {
            this.dispatchEvent(this.el, signalEventName(this.nameValue, 'match'));
            return;
        }
        if (this.allPredicatesMatch(value)) {
            this.dispatchEvent(this.el, signalEventName(this.nameValue, 'match'), { detail: { element: this.el, value } });
        }
        else {
            this.dispatchEvent(this.el, signalEventName(this.nameValue, 'no-match'), { detail: { element: this.el, value } });
        }
    }
}
SignalActionController.values = {
    name: String,
    when: String,
};
