import { useMixin } from "./create_mixin";
export function useInterval(controller, handler, interval) {
    handler = handler.bind(controller);
    let intervalHandle = null;
    let setup = () => intervalHandle = setInterval(handler, interval);
    let teardown = () => {
        if (intervalHandle !== null) {
            clearInterval(intervalHandle);
        }
    };
    useMixin(controller, setup, teardown);
    return teardown;
}
