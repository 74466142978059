import { BaseController } from "../../utilities/base_controller";
import { useIntersection } from "../../mixins/use_intersection";
import { EasingFunctions } from "../../utilities/easingFunctions";
export class TweenNumberController extends BaseController {
    get start() {
        if (this.hasStartValue) {
            return this.startValue;
        }
        else {
            throw new Error("Required value `start` is missing");
        }
    }
    get end() {
        if (this.hasEndValue) {
            return this.endValue;
        }
        else {
            throw new Error("Required value `end` is missing");
        }
    }
    get durationMs() {
        if (this.hasDurationValue) {
            return this.durationValue;
        }
        else {
            throw new Error("Required value `duration` is missing");
        }
    }
    get easingFunction() {
        let str = this.hasEasingValue ? this.easingValue : null;
        let fallback = EasingFunctions.linear;
        if (str == null) {
            return fallback;
        }
        // @ts-ignore
        return EasingFunctions[str] || fallback;
    }
    connect() {
        useIntersection(this, this.el, this.appear);
    }
    appear(_entry) {
        this.tween();
    }
    ;
    tween() {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) {
                startTimestamp = timestamp;
            }
            const elapsed = timestamp - startTimestamp;
            const progress = Math.min(elapsed / this.durationMs, 1);
            this.element.innerHTML = Math.floor(this.easingFunction(progress) * (this.end - this.start) + this.start).toString();
            if (progress < 1) {
                requestAnimationFrame(step);
            }
        };
        requestAnimationFrame(step);
    }
}
TweenNumberController.values = {
    start: Number,
    end: Number,
    duration: Number,
    easing: String,
};
