import debounce from "lodash-es/debounce";
import _get from "lodash-es/get";
import _set from "lodash-es/set";
import { BaseController } from '../utilities/base_controller';
import { useLocalStorage } from "../mixins";
export class ElementSaveController extends BaseController {
    get _id() {
        if (this.hasIdValue) {
            return this.idValue;
        }
        let elementID = this.el.id;
        if (elementID !== "") {
            return elementID;
        }
        else {
            throw new Error(`No ID value to uniquely identify this element. Please either specify data-${this.identifier}-id-value or give this element an 'id' attribute. `);
        }
    }
    get _uniqueIdentifier() {
        const url = location.href;
        return `${url} ${this._id}`;
    }
    get _restoreOnLoad() {
        return this.hasRestoreOnLoadValue ? this.restoreOnLoadValue : true;
    }
    get _element() {
        return this.hasElementTarget ? this.elementTarget : this.el;
    }
    initialize() {
        this.save = debounce(this.save.bind(this), 300);
    }
    connect() {
        this._store = useLocalStorage(this, this._uniqueIdentifier, {});
        requestAnimationFrame(() => {
            if (this._restoreOnLoad) {
                this.restore();
            }
        });
    }
    clear(event) {
        if (event) {
            event.preventDefault();
        }
        this._store.clear();
        this.dispatchEvent(this._element, `element-save:cleared`);
    }
    save(event) {
        if (event) {
            event.preventDefault();
        }
        let element = this._element;
        let attributes = this.attributesValue.split(" ");
        let data = {};
        attributes.forEach((attr) => data[attr] = _get(element, attr));
        this._store.value = data;
        this.dispatchEvent(element, `element-save:save:success`);
    }
    restore(event) {
        if (event) {
            event.preventDefault();
        }
        let element = this._element;
        if (!this._store.isEmpty()) {
            const savedData = this._store.value; // get and parse the saved data from localStorage
            Object.keys(savedData).forEach((attr) => _set(element, attr, savedData[attr]));
            this.dispatchEvent(element, `element-save:restore:success`);
        }
        else {
            this.dispatchEvent(element, `element-save:restore:empty`);
        }
    }
}
ElementSaveController.targets = [
    "element",
];
ElementSaveController.values = {
    id: String,
    attributes: String,
    restoreOnLoad: Boolean,
};
