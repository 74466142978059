import { BaseController } from "../../utilities/base_controller";
export class CheckboxDisableInputsController extends BaseController {
    connect() {
        this.toggle();
    }
    toggle() {
        if (this.hasDisablerTarget && this.disablerTarget.checked) {
            this.disable();
        }
        else {
            this.enable();
        }
    }
    disable() {
        let shouldClear = this.hasClearValue && this.clearValue;
        this.disableTargets.forEach((el, _) => {
            if (shouldClear) {
                el.value = "";
            }
            el.disabled = true;
        });
    }
    enable() {
        this.disableTargets.forEach((el, _) => {
            el.disabled = false;
        });
    }
}
CheckboxDisableInputsController.targets = ["disabler", "disable"];
CheckboxDisableInputsController.values = {
    clear: Boolean,
};
