var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseController } from "../../utilities/base_controller";
import { scrollToElement } from "../../utilities/scroll";
import clamp from "lodash-es/clamp";
import { installClassMethods } from "../../mixins/install_class_methods";
export class NavigateFormErrorsController extends BaseController {
    constructor() {
        super(...arguments);
        this._errors = [];
        this._firstClick = false;
    }
    get defaultCurrentClasses() {
        return ["currentError"];
    }
    get _errorCount() {
        return this._errors.length;
    }
    get _previousIndex() {
        let index = this._index - 1;
        if (index < 0) {
            return 0;
        }
        return index;
    }
    get _nextIndex() {
        let index = this._index + 1;
        if (index > this._errors.length - 1) {
            return this._errors.length - 1;
        }
        return index;
    }
    get _index() {
        return clamp(this.hasIndexValue ? this.indexValue : 0, 0, this._errors.length);
    }
    get _selector() {
        if (this.hasSelectorValue) {
            return this.selectorValue;
        }
        else {
            throw new Error("Expected `selectorValue` to be present");
        }
    }
    get _currentError() {
        return this._errors[this._index];
    }
    get _otherErrors() {
        return this._errors.filter((error, index) => index !== this._index);
    }
    connect() {
        installClassMethods(this);
        requestAnimationFrame(() => {
            this._firstClick = true;
            this._toggleButtons();
            this._toggleVisibility();
        });
    }
    current(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            if (this._firstClick) {
                this._firstClick = false;
                this._toggleButtons();
            }
            yield scrollToElement(this._currentError, { behavior: "smooth", block: "center", inline: "center" });
            this._updateClasses();
        });
    }
    next(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            if (this._firstClick) {
                this._firstClick = false;
                this._toggleButtons();
            }
            else {
                this.indexValue = this._nextIndex;
            }
            yield scrollToElement(this._currentError, { behavior: "smooth", block: "center", inline: "center" });
            this._updateClasses();
        });
    }
    previous(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            if (this._firstClick) {
                this._firstClick = false;
                this._toggleButtons();
            }
            else {
                this.indexValue = this._previousIndex;
            }
            yield scrollToElement(this._currentError, { behavior: "smooth", block: "center", inline: "center" });
            this._updateClasses();
        });
    }
    indexValueChanged() {
        this._toggleButtons();
    }
    selectorValueChanged() {
        this._errors = Array.from(document.querySelectorAll(this._selector));
        this.indexValue = 0;
        this._toggleButtons();
        this._toggleVisibility();
    }
    _updateClasses() {
        this.addCurrentClasses(this._currentError);
        this._otherErrors.forEach((error) => this.removeCurrentClasses(error));
    }
    _toggleVisibility() {
        if (this._errorCount === 0) {
            this.el.style.display = "none";
        }
        else {
            this.el.style.display = "";
        }
    }
    _toggleButtons() {
        if (this.hasNextTarget) {
            // If there is no "Current Error" button, then enable the next error button when there is only 1 error.
            if (!this.hasCurrentTarget && this._firstClick && this.indexValue == this._errorCount - 1) {
                this.nextTarget.removeAttribute("disabled");
                return;
            }
            else if (this.indexValue >= this._errorCount - 1) {
                this.nextTarget.setAttribute("disabled", "true");
            }
            else {
                this.nextTarget.removeAttribute("disabled");
            }
        }
        if (this.hasPreviousTarget) {
            if (this.indexValue <= 0) {
                this.previousTarget.setAttribute("disabled", "true");
            }
            else {
                this.previousTarget.removeAttribute("disabled");
            }
        }
    }
}
NavigateFormErrorsController.values = {
    selector: String,
    index: Number,
};
NavigateFormErrorsController.classes = ["current"];
NavigateFormErrorsController.targets = ["next", "current", "previous"];
