import { useMixin } from "./create_mixin";
export function useTimeout(controller, handler, timeout) {
    let controllerDisconnect;
    let timeoutHandle = null;
    handler = handler.bind(controller);
    let newHandler = () => {
        handler();
        timeoutHandle = null;
        Object.assign(controller, { disconnect: controllerDisconnect });
    };
    let setup = () => timeoutHandle = setTimeout(newHandler, timeout);
    let teardown = () => {
        if (timeoutHandle !== null) {
            clearTimeout(timeoutHandle);
            timeoutHandle = null;
        }
    };
    controllerDisconnect = useMixin(controller, setup, teardown);
    return teardown;
}
