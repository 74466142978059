import { BaseController } from "../../utilities/base_controller";
import { useCollectionEventListener } from "../../mixins/use_event_listener";
export class LimitedSelectionCheckboxesController extends BaseController {
    connect() {
        useCollectionEventListener(this, this.inputTargets, "change", this._handleInputs);
    }
    _handleInputs(event) {
        let tickedInputs = this.inputTargets.reduce((previousValue, el) => el.checked ? previousValue + 1 : previousValue, 0);
        let target = event.target;
        if (tickedInputs > this.maxValue) {
            event.preventDefault();
            target.checked = false;
            this.dispatchEvent(target, "change");
            this.dispatchEvent(target, "limited-selection:too-many");
            if (this.hasErrorTarget && this.hasMessageValue) {
                this.errorTarget.innerHTML = this.messageValue;
            }
        }
        else {
            this.dispatchEvent(target, "limited-selection:selection");
            if (this.hasErrorTarget) {
                this.errorTarget.innerHTML = "";
            }
        }
    }
}
LimitedSelectionCheckboxesController.targets = ["input", "error"];
LimitedSelectionCheckboxesController.values = { max: Number, message: String };
