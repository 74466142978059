import { useMixin } from "./create_mixin";
export function useIntersectionObserver(controller, handler, options) {
    handler = handler.bind(controller);
    let observer = new IntersectionObserver(handler, options);
    let teardown = () => {
        observer === null || observer === void 0 ? void 0 : observer.disconnect();
        observer = null;
    };
    let observe = (element) => observer === null || observer === void 0 ? void 0 : observer.observe(element);
    let unobserve = (element) => observer === null || observer === void 0 ? void 0 : observer.unobserve(element);
    return {
        observer,
        teardown,
        observe,
        unobserve,
    };
}
export function useIntersection(controller, element, appear, disappear, options) {
    if (appear) {
        appear = appear.bind(controller);
    }
    if (disappear) {
        disappear = disappear.bind(controller);
    }
    let opts = options !== null && options !== void 0 ? options : {};
    let processEntries = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                appear && appear(entry);
            }
            else {
                disappear && disappear(entry);
            }
        });
    };
    let { observer, observe, unobserve, teardown } = useIntersectionObserver(controller, processEntries, opts);
    let setup = () => observe(element);
    useMixin(controller, setup, teardown);
    return {
        observer,
        observe: () => observe(element),
        unobserve: () => unobserve(element),
        teardown,
    };
}
