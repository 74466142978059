import { BaseController } from "../utilities";
export class RefreshPageController extends BaseController {
    get onLoad() {
        return this.hasOnLoadValue ? this.onLoadValue : false;
    }
    connect() {
        if (this.onLoad) {
            this.refresh();
        }
    }
    refresh() {
        location.reload();
    }
}
RefreshPageController.values = {
    onLoad: Boolean,
};
