import { insertHiddenButton } from "./elements";
export function requestSubmit(form) {
    if (form.requestSubmit) {
        form.requestSubmit();
    }
    else {
        let button = form.querySelector('button[type="submit"]');
        if (!button) {
            button = insertHiddenButton("submit", form, 'beforeend');
        }
        button.click();
    }
}
export function requestReset(form) {
    let button = form.querySelector('button[type="reset"]');
    if (!button) {
        button = insertHiddenButton("reset", form, 'beforeend');
    }
    button.click();
}
