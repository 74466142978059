import { BaseController } from "../utilities/base_controller";
import { useTimeout } from "../mixins/use_timeout";
import { useEventListener } from "../mixins/use_event_listener";
export class BackLinkController extends BaseController {
    get _pages() {
        return this.hasPagesValue ? Math.abs(this.pagesValue) : 1;
    }
    get _timeout() {
        return this.hasTimeoutValue ? this.timeoutValue : 1500;
    }
    connect() {
        useEventListener(this, this.el, "click", this.goBack);
    }
    goBack(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        history.go(-this._pages);
        if (this.el.href) {
            useTimeout(this, this.fallback, this._timeout);
        }
    }
    fallback() {
        window.location.href = this.el.href;
    }
}
BackLinkController.values = { timeout: Number, pages: Number };
