var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseController } from "../../utilities/base_controller";
import { fetchRetry } from "../../utilities/fetchRetry";
export class LoadBlockController extends BaseController {
    get _errorMessage() {
        return this.hasErrorMessageValue ? this.errorMessageValue : "This content failed to load";
    }
    get _maxRetries() {
        return this.hasMaxRetriesValue ? this.maxRetriesValue : 1;
    }
    connect() {
    }
    loadContent(event = null) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            let self = this;
            let el = this.hasReplaceTarget ? this.replaceTarget : this.el;
            let failure = () => {
                el.replaceWith(this._errorMessage);
                self.dispatchEvent(el, "ajax:error");
            };
            try {
                let response = yield fetchRetry(this._maxRetries, this.endpointValue);
                if (!response.ok) {
                    failure();
                }
                let text = yield response.text();
                let newEl = document.createElement("div");
                newEl.innerHTML = text;
                if (this.hasSelectorValue) {
                    let selectedContent = newEl.querySelectorAll(this.selectorValue);
                    el.replaceWith(...selectedContent);
                }
                else {
                    el.replaceWith(...newEl.children);
                }
                // Trigger event to show block has loaded
                self.dispatchEvent(el, "ajax:success");
            }
            catch (e) {
                failure();
            }
            finally {
                self.dispatchEvent(el, "ajax:complete");
            }
        });
    }
}
LoadBlockController.targets = ["replace"];
LoadBlockController.values = {
    endpoint: String,
    errorMessage: String,
    selector: String,
    maxRetries: Number,
};
