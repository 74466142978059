export function isHTMLLinkElement(element) {
    return element.nodeName == "LINK";
}
export function isHTMLAnchorElement(element) {
    return element.nodeName == "A";
}
export function isHTMLFormElement(element) {
    return element.nodeName == "FORM";
}
export function isHTMLInputElement(element) {
    return element.nodeName == "INPUT";
}
export function isHTMLLabelElement(element) {
    return element.nodeName == "LABEL";
}
export function isHTMLTextAreaElement(element) {
    return element.nodeName == "TEXTAREA";
}
export function isHTMLButtonElement(element) {
    return element.nodeName == "BUTTON";
}
export function isHTMLSelectElement(element) {
    return element.nodeName == "SELECT";
}
export function isHTMLImageElement(element) {
    return element.nodeName == "IMG";
}
export function isHTMLButtonInputElement(element) {
    return element.nodeName == "INPUT" && element.type == "button";
}
export function isHTMLSubmitInputElement(element) {
    return element.nodeName == "INPUT" && element.type == "submit";
}
export function isHTMLResetInputElement(element) {
    return element.nodeName == "INPUT" && element.type == "reset";
}
export function isHTMLButtonButtonElement(element) {
    return element.nodeName == "BUTTON" && element.type == "button";
}
export function isHTMLSubmitButtonElement(element) {
    return element.nodeName == "BUTTON" && element.type == "submit";
}
export function isHTMLResetButtonElement(element) {
    return element.nodeName == "BUTTON" && element.type == "reset";
}
export function isTypeOfResetButtonElement(element) {
    return isHTMLResetButtonElement(element) || isHTMLResetInputElement(element);
}
export function isTypeOfSubmitButtonElement(element) {
    return isHTMLSubmitButtonElement(element) || isHTMLSubmitInputElement(element);
}
export function isTypeOfButtonableElement(element) {
    return isTypeOfResetButtonElement(element) || isTypeOfSubmitButtonElement(element) || isHTMLButtonButtonElement(element);
}
export function isElementCheckable(element) {
    return isHTMLInputElement(element) && (element.type === "radio" || element.type === "checkbox");
}
export function isTypeOfFormInputElement(element) {
    return isHTMLInputElement(element) || isHTMLSelectElement(element) || isHTMLTextAreaElement(element);
}
export function createHiddenButton(type) {
    let button = document.createElement('button');
    button.type = type;
    button.style.display = 'none';
    button.dataset.sythentic = 'true';
    return button;
}
export function createHiddenInput(name, value) {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
}
export function insertElement(targetElement, insertPosition, element) {
    let createdElement = targetElement.insertAdjacentElement(insertPosition, element);
    if (!createdElement) {
        throw new Error(`Failed to insert element ${element.nodeName} into ${targetElement.nodeName}`);
    }
    return createdElement;
}
export function insertHiddenInput(name, value, targetElement, insertPosition) {
    return insertElement(targetElement, insertPosition, createHiddenInput(name, value));
}
export function insertHiddenButton(type, targetElement, insertPosition) {
    return insertElement(targetElement, insertPosition, createHiddenButton(type));
}
export function getAllRadiosInGroup(radio) {
    let parent = radio.form || document;
    return Array.from(parent.querySelectorAll(`input[type="radio"][name="${radio.name}"]`));
}
export function getOtherRadiosInGroup(radio) {
    return getAllRadiosInGroup(radio).filter((r) => r !== radio);
}
export function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
    return vertInView && horInView;
}
