import { BaseController } from "../../utilities/base_controller";
import { scrollToElement } from "../../utilities/scroll";
export class ScrollToController extends BaseController {
    scroll() {
        let target = document.querySelector(this.selectorValue);
        if (!target) {
            console.warn(`Could not find target for '${this.selectorValue}'`);
            return;
        }
        scrollToElement(target, {
            behavior: this.hasBehaviorValue ? this.behaviorValue : "smooth",
            block: this.hasBlockValue ? this.blockValue : "center",
            inline: this.hasInlineValue ? this.inlineValue : "center",
        }).catch(() => target.scrollIntoView()); // Fallback to snap-scrolling
    }
}
ScrollToController.values = {
    selector: String,
    behavior: String,
    block: String,
    inline: String,
};
