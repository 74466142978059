import { BaseController } from "../utilities/base_controller";
import { isHTMLAnchorElement } from "../utilities/elements";
import { isTurboFrame } from "../utilities/turbo";
export class TurboFrameRCController extends BaseController {
    toggle(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        let frame = this._getFrame();
        let frameSrc = frame.src;
        if (frameSrc == null || frameSrc !== this._getSrc()) {
            this._setSrc();
        }
        else {
            this._clear();
        }
    }
    setSrc(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this._setSrc();
    }
    clear(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this._clear();
    }
    _setSrc() {
        let frame = this._getFrame();
        if (this.hasLoadingMessageValue) {
            frame.innerHTML = this.loadingMessageValue;
        }
        frame.src = this._getSrc();
    }
    _clear() {
        let frame = this._getFrame();
        frame.src = "";
        frame.innerHTML = "";
    }
    _getFrame() {
        let frame = document.getElementById(`${this.frameIdValue}`);
        if (frame == null) {
            throw new Error(`Could not find frame with ID '${this.frameIdValue}'`);
        }
        if (!isTurboFrame(frame)) {
            throw new Error(`Element targeted by ID '${this.frameIdValue}'`);
        }
        else {
            return frame;
        }
    }
    _getSrc() {
        let element = this.el;
        if (this.hasSrcValue) {
            return this.srcValue;
        }
        else if (isHTMLAnchorElement(element)) {
            return element.href;
        }
        else {
            throw new Error("No link given to drive frame to");
        }
    }
}
TurboFrameRCController.values = {
    frameId: String,
    src: String,
    loadingMessage: String,
};
