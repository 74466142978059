var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LoadBlockController } from "./load_block_controller";
import { useIntersection } from "../../mixins/use_intersection";
export class LazyBlockController extends LoadBlockController {
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            let element = this.el;
            if ("IntersectionObserver" in window) {
                let { observe, unobserve } = useIntersection(this, element, this.appear, null, { threshold: 0.3 });
                this.observe = observe;
                this.unobserve = unobserve;
            }
            else {
                // Client doesn't support intersection observer, fallback to pre-loading
                yield this.loadContent();
            }
        });
    }
    appear(entry) {
        return __awaiter(this, void 0, void 0, function* () {
            if (entry.target === this.el && entry.isIntersecting) {
                yield this.loadContent();
                if (this.unobserve) {
                    this.unobserve();
                }
            }
        });
    }
}
