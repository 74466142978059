var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseController } from "../../utilities/base_controller";
import { scrollAbsoluteBottom, scrollAbsoluteLeft, scrollAbsoluteTop, scrollDown, scrollLeft, scrollRight, scrollUp } from "../../utilities/scroll";
export class ScrollContainerController extends BaseController {
    get _increment() {
        return this.hasIncrementValue ? this.incrementValue : 50;
    }
    get _behaviour() {
        if (this.hasBehaviourValue) {
            if (["auto", "smooth"].includes(this.behaviourValue)) {
                return this.behaviourValue;
            }
            else {
                throw new Error(`'${this.behaviourValue}' is not a recognised scroll behaviour`);
            }
        }
        else {
            return "auto";
        }
    }
    scrollTop(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollAbsoluteTop(this.el, { behavior: this._behaviour });
        });
    }
    scrollBottom(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollAbsoluteBottom(this.el, { behavior: this._behaviour });
        });
    }
    scrollLeft(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollAbsoluteLeft(this.el, { behavior: this._behaviour });
        });
    }
    scrollRight(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollAbsoluteLeft(this.el, { behavior: this._behaviour });
        });
    }
    up(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollUp(this.el, this._increment, { behavior: this._behaviour });
        });
    }
    down(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollDown(this.el, this._increment, { behavior: this._behaviour });
        });
    }
    left(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollLeft(this.el, this._increment, { behavior: this._behaviour });
        });
    }
    right(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield scrollRight(this.el, this._increment, { behavior: this._behaviour });
        });
    }
}
ScrollContainerController.values = {
    behaviour: String,
    increment: Number,
};
