import debounce from "lodash-es/debounce";
import { wrapArray } from "../utilities/arrays";
import { useMixin } from "./create_mixin";
export function useEventListener(controller, element, eventNameOrNames, handler, opts) {
    if (opts === null || opts === void 0 ? void 0 : opts.debounce) {
        handler = debounce(handler.bind(controller), opts.debounce);
        delete opts.debounce;
    }
    else {
        handler = handler.bind(controller);
    }
    let eventNames = wrapArray(eventNameOrNames);
    let setup = () => eventNames.forEach(eventName => element.addEventListener(eventName, handler, opts));
    let teardown = () => eventNames.forEach(eventName => element.removeEventListener(eventName, handler));
    useMixin(controller, setup, teardown);
    return { setup, teardown };
}
export function useEventListeners(controller, element, eventNameOrNames, handler, opts) {
    return useEventListener(controller, element, eventNameOrNames, handler, opts);
}
export function useCollectionEventListener(controller, elements, eventNameOrNames, handler, opts) {
    let handlers = [];
    elements.forEach(el => {
        let { setup, teardown } = useEventListener(controller, el, eventNameOrNames, handler, opts);
        handlers.push({ setup, teardown });
    });
    return [
        () => handlers.forEach(h => h.setup()),
        () => handlers.forEach(h => h.teardown()),
    ];
}
export function useCollectionEventListeners(controller, elements, eventNameOrNames, handler, opts) {
    return useCollectionEventListener(controller, elements, eventNameOrNames, handler, opts);
}
