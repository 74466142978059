import { EphemeralController } from "../../utilities/ephemeral_controller";
import { scrollToElement } from "../../utilities/scroll";
export class ScrollIntoFocusController extends EphemeralController {
    connect() {
        requestAnimationFrame(() => {
            // Attempt smooth scrolling, with polyfill
            scrollToElement(this.el, {
                behavior: this.hasBehaviorValue ? this.behaviorValue : "smooth",
                block: this.hasBlockValue ? this.blockValue : "center",
                inline: this.hasInlineValue ? this.inlineValue : "center",
            }).catch(() => this.el.scrollIntoView()); // Fallback to snap-scrolling
            this._cleanupSelf();
        });
    }
}
ScrollIntoFocusController.values = {
    behavior: String,
    block: String,
    inline: String,
};
