import { signalVisibilityEvent } from "./events";
import { SignalBaseController } from "./base_controller";
export class SignalVisibilityController extends SignalBaseController {
    get defaultHideClasses() {
        return ["hide"];
    }
    _onSignal(payload) {
        let value = payload.value;
        if (this.showValue == "default") {
            if (value == "") {
                this.removeHideClasses(this.el);
            }
            else {
                this.addHideClasses(this.el);
            }
            return;
        }
        if (this.allPredicatesMatch(value)) {
            this.dispatchEvent(this.el, signalVisibilityEvent(this.nameValue, "show"), { detail: { predicate: this.showValue, value } });
            this.removeHideClasses(this.el);
        }
        else {
            this.dispatchEvent(this.el, signalVisibilityEvent(this.nameValue, "hide"), { detail: { predicate: this.showValue, value } });
            this.addHideClasses(this.el);
        }
    }
}
SignalVisibilityController.values = {
    name: String,
    show: String,
};
SignalVisibilityController.classes = ['hide'];
