import { Controller } from "@hotwired/stimulus";
import { controllerMethod } from "../utilities/stimulus";
import { pascalCase } from "../utilities/strings";
export class InstallClassMethodComposableController extends Controller {
}
function addMethodsForClassDefinition(controller, name) {
    let defaultElement = controller.element;
    let hasClass = () => controller[`has${pascalCase(name)}Class`] == true;
    let classes = () => controller[`${name}Classes`];
    let defaultClasses = () => controllerMethod(controller, `default${pascalCase(name)}Classes`).call(controller) || [];
    let classOrDefault = () => hasClass() ? classes() : defaultClasses();
    if (controller[`${name}Classes`] == undefined) {
        Object.defineProperty(controller, `${name}Classes`, {
            get: () => hasClass() ? controller[`${name}Class`].split(' ') : defaultClasses(),
        });
    }
    let methods = {
        [`add${pascalCase(name)}Classes`]: (element = defaultElement) => element.classList.add(...classOrDefault()),
        [`remove${pascalCase(name)}Classes`]: (element = defaultElement) => element.classList.remove(...classOrDefault()),
        [`${name}ClassesPresent`]: (element = defaultElement) => classOrDefault().every((klass) => element.classList.contains(klass)),
    };
    Object.assign(controller, methods);
}
export function installClassMethods(controller) {
    // @ts-ignore
    let classes = controller.constructor.classes || [];
    classes.forEach((classDefinition) => addMethodsForClassDefinition(controller, classDefinition));
}
