import { BaseController } from "../utilities/base_controller";
import { useTimeout } from "../mixins/use_timeout";
export class SelfDestructController extends BaseController {
    connect() {
        requestAnimationFrame(() => {
            // Only start countdown on first paint
            useTimeout(this, () => this.el.remove(), this.secondsValue * 1000);
        });
    }
}
SelfDestructController.values = { seconds: Number };
