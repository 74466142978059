import { BaseController } from "../../utilities/base_controller";
import { useIntersection } from "../../mixins/use_intersection";
import { dispatchEvent } from "../../utilities/events";
export class IntersectionController extends BaseController {
    get _threshold() {
        if (this.hasThresholdValue) {
            return this.thresholdValue.split(',').map(val => Number.parseFloat(val.trim())).filter(val => val >= 0 && val <= 1);
        }
        else {
            return [0, 1];
        }
    }
    connect() {
        useIntersection(this, this.el, this.appear, this.disappear, { threshold: this._threshold });
    }
    appear(entry) {
        dispatchEvent(this, this.el, "intersection:appear");
    }
    ;
    disappear(entry) {
        dispatchEvent(this, this.el, "intersection:disappear");
    }
    ;
}
IntersectionController.values = { threshold: String };
