import { BaseController } from "../../utilities/base_controller";
export class CheckboxEnableInputsController extends BaseController {
    connect() {
        this.toggle();
    }
    toggle() {
        if (this.hasEnablerTarget && this.enablerTarget.checked) {
            this.enable();
        }
        else {
            this.disable();
        }
    }
    disable() {
        let shouldClear = this.hasClearValue && this.clearValue;
        this.enableTargets.forEach((el, _) => {
            if (shouldClear) {
                el.value = "";
            }
            el.disabled = true;
        });
    }
    enable() {
        this.enableTargets.forEach((el, _) => {
            el.disabled = false;
        });
    }
}
CheckboxEnableInputsController.targets = ["enabler", "enable"];
CheckboxEnableInputsController.values = {
    clear: Boolean,
};
