import { BaseController } from "../../utilities/base_controller";
export class PasswordPeekController extends BaseController {
    peak(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.passwordTarget.type = "text";
    }
    hide(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.passwordTarget.type = "password";
    }
    toggle(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        if (this.passwordTarget.type === "password") {
            this.peak();
        }
        else {
            this.hide();
        }
    }
}
PasswordPeekController.targets = ["password"];
