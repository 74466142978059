var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMixin } from "./create_mixin";
export function useFullscreen(controller, el) {
    let element = el || document.documentElement;
    let fullscreenOpen = document.fullscreenElement !== null;
    const updateFullscreenState = () => fullscreenOpen = document.fullscreenElement !== null;
    const isFullscreen = () => fullscreenOpen;
    const toggle = () => __awaiter(this, void 0, void 0, function* () { return fullscreenOpen ? yield exit() : yield enter(); });
    let setup = () => document.addEventListener('fullscreenchange', updateFullscreenState);
    let teardown = () => document.removeEventListener('fullscreenchange', updateFullscreenState);
    const exit = () => __awaiter(this, void 0, void 0, function* () {
        if (document.exitFullscreen) {
            fullscreenOpen = false;
            yield document.exitFullscreen();
        }
    });
    const enter = () => __awaiter(this, void 0, void 0, function* () {
        if (fullscreenOpen) {
            yield exit();
        }
        if (element.requestFullscreen) {
            yield element.requestFullscreen();
            fullscreenOpen = true;
        }
    });
    useMixin(controller, setup, teardown);
    return {
        isFullscreen,
        enter,
        exit,
        toggle,
        teardown,
    };
}
