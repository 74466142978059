var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseController } from "../../utilities/base_controller";
export class MediaPlayerController extends BaseController {
    play(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            yield this.mediaTarget.play();
        });
    }
    pause(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.mediaTarget.pause();
    }
    restart(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.mediaTarget.currentTime = 0;
    }
    seek(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.mediaTarget.currentTime += 5;
    }
}
MediaPlayerController.targets = ["media"];
