var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import smoothScroll from 'smoothscroll-polyfill';
export function scrollToElement(element, { behavior = "smooth", block = "start", inline = "nearest" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollIntoView({ behavior, block, inline });
    });
}
export function scrollAbsoluteTop(element, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollTo({ top: 0, left: 0, behavior });
    });
}
export function scrollAbsoluteBottom(element, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        if (element == window) {
            element.scrollTo({ top: document.body.scrollHeight, left: 0, behavior });
        }
        else {
            element.scrollTo({ top: element.scrollHeight, left: 0, behavior });
        }
    });
}
export function scrollAbsoluteLeft(element, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollTo({ left: 0, behavior });
    });
}
export function scrollAbsoluteRight(element, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        if (element == window) {
            element.scrollTo({ left: document.body.scrollWidth, behavior });
        }
        else {
            element.scrollTo({ left: element.scrollWidth, behavior });
        }
    });
}
export function scrollUp(element, amount, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollBy({ top: -amount, left: 0, behavior });
    });
}
export function scrollDown(element, amount, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollBy({ top: amount, left: 0, behavior });
    });
}
export function scrollLeft(element, amount, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollBy({ top: 0, left: -amount, behavior });
    });
}
export function scrollRight(element, amount, { behavior = "smooth" } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        smoothScroll.polyfill();
        element.scrollBy({ top: 0, left: amount, behavior });
    });
}
export function getScrollParent(node) {
    smoothScroll.polyfill();
    if (!node) {
        return null;
    }
    if (node == document.body) {
        return window;
    }
    const overflowY = getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";
    if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }
    return getScrollParent(node.parentElement) || document.body;
}
