var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LoadBlockController } from "./load_block_controller";
export class AsyncBlockController extends LoadBlockController {
    get _errorMessage() {
        return this.hasErrorMessageValue ? this.errorMessageValue : "This content failed to load";
    }
    // This is a simple controller to load a block of content when the page loads.
    // It should be used to give a quick initial response before calling out to an
    // an AJAX endpoint to do some expensive work.
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadContent();
        });
    }
}
AsyncBlockController.targets = ["replace"];
AsyncBlockController.values = {
    endpoint: String,
    errorMessage: String,
    selector: String,
    maxRetries: Number,
};
